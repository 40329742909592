<template>
    <v-container>
        <v-tabs v-model="tab" grow show-arrows>
            <v-tab v-for="(tab, value) in tabs" :value="value" :disabled="tab.disabled" nuxt="true"
                :to="`/analytics/${value}`">
                {{ tab.title }}
            </v-tab>
        </v-tabs>

        <slot />
    </v-container>
</template>

<script setup lang="ts">
const tabs: { [key: string]: { title: string, disabled: boolean } } = {
    "eia": { title: "Environment", disabled: false },
    "ems": { title: "Energy", disabled: false },
    "assets": { title: "Assets", disabled: false },
    "HCM": { title: "HCM", disabled: true },
    "MFG": { title: "MFG", disabled: true },
}

const route = useRoute()
const tab = ref(route.path)
</script>
